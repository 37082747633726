import { navigate } from "gatsby";
import decode, { JwtPayload } from "jwt-decode";
import * as React from "react";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type DecodedToken = JwtPayload & {
  uid: string;
  name: string;
  email: string;
};

const STORAGE_KEY = "ablesetIdToken";

export const isExpired = (user: DecodedToken) => {
  return !user.exp || user.exp <= Date.now() / 1000;
};

const useUserContext = () => {
  const [idToken, _setIdToken] = useState<string | null>(null);
  const [user, setUser] = useState<DecodedToken | null>(null);

  const logout = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    _setIdToken(null);
    setUser(null);
    navigate("/");
  }, []);

  const setIdToken = useCallback(
    (idToken: string) => {
      try {
        const res = decode<DecodedToken>(idToken);

        if (!res || typeof res === "string") {
          throw new Error("No decoded token");
        }

        if (isExpired(res)) {
          return;
        }

        _setIdToken(idToken);
        localStorage.setItem(STORAGE_KEY, idToken);
        setUser(res);
      } catch (e) {
        console.error(e);
        logout();
      }
    },
    [idToken],
  );

  useEffect(() => {
    const token =
      typeof window !== "undefined" ? localStorage.getItem(STORAGE_KEY) : null;

    if (token) {
      setIdToken(token);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user && isExpired(user)) {
        console.warn("User token expired", user, Date.now() / 1000);
        logout();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [user]);

  const login = useCallback(async (loginKey: string) => {
    const res = await fetch("/api/retrieve-id-token?loginKey=" + loginKey);
    const body = await res.json();

    if (res.ok) {
      setIdToken(body.idToken);
      return true;
    } else {
      throw new Error(body.error ?? "Unknown error");
    }
  }, []);

  const sendLoginEmail = useCallback(async (emailOrLicense: string) => {
    const res = await fetch("/api/send-login-email", {
      method: "post",
      body: JSON.stringify({ emailOrLicense }),
    });

    if (res.ok) {
      return true;
    } else {
      throw new Error((await res.json()).error ?? "Unknown error");
    }
  }, []);

  return { user, idToken, sendLoginEmail, login, logout };
};

const UserContext = createContext<ReturnType<typeof useUserContext> | null>(
  null,
);

export const UserProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const user = useUserContext();

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error("useUser has to be used inside a UserProvider");
  }

  return user;
};
