/// <reference path="src/types/clarity.ts" />

import { RouteUpdateArgs } from "gatsby";
import "./src/css/style.css";

if (typeof window !== "undefined" && !document.getElementById("ls-script")) {
  // LemonSqueezy Affiliate Config
  (window as any).lemonSqueezyAffiliateConfig = { store: "ableset" };

  const lsScript = document.createElement("script");
  lsScript.defer = true;
  lsScript.id = "ls-script";
  lsScript.src = "https://lmsqueezy.com/affiliate.js";
  document.head.append(lsScript);
}

if (
  typeof window !== "undefined" &&
  !document.getElementById("plausible-script")
) {
  const script2 = document.createElement("script");
  script2.async = true;
  script2.defer = true;
  script2.id = "plausible-script";
  script2.setAttribute("data-domain", "ableset.app");
  script2.src = "https://plausible.io/js/plausible.js";
  document.head.append(script2);

  (window as any).plausible = function () {
    ((window as any).plausible.q =
      ((window as any).plausible as any).q || []).push(arguments);
  };
}

if (typeof window !== "undefined") {
  window.clarity =
    window.clarity ||
    function () {
      (window.clarity!.q = window.clarity!.q || []).push(arguments);
    };
  const t = document.createElement("script");
  t.async = true;
  t.src = "https://www.clarity.ms/tag/hntv7rhdee";
  const y = document.getElementsByTagName("script")[0];
  y.parentNode?.insertBefore(t, y);
}

export const onPreRouteUpdate = () => {
  document.documentElement.classList.add("navigating");
};

export const onRouteUpdate = (args: RouteUpdateArgs) => {
  if (args.location.hash) {
    queueMicrotask(() => {
      document.documentElement.classList.remove("navigating");
      const id = args.location.hash.substring(1);
      const el = document.getElementById(id);
      el?.scrollIntoView({ block: "start" });
    });
  }
  setTimeout(() => {
    document.documentElement.classList.remove("navigating");
  }, 50);
};

export { wrapRootElement } from "./src/util/wrapper";
