import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GatsbyBrowser } from "gatsby";
import { UserProvider } from "./user";

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>{element}</UserProvider>
    </QueryClientProvider>
  );
};
